import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Dropdown } from "react-bootstrap";

const useGetCategories = () => {
    const categories = useStaticQuery(graphql`
        query{
            allWpCategory(sort: {fields: name, order: ASC}, filter: {name: {nin: "Uncategorized"}}) {
                totalCount
                edges {
                    node {
                        id
                        name
                        slug
                        posts {
                            nodes {
                              title
                            }
                        }
                    }
                }
            }
        }
    `)
    return categories.allWpCategory
}

export const CategoriesList = () => {
    const { edges } = useGetCategories()
    
    return (
        <div className="categories" >
            <Dropdown className="category-btn text-right text-lg-left text-md-left">
                <Dropdown.Toggle variant="primary outline" id="dropdown-basic">
                    Category
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="/">All Posts</Dropdown.Item>
                    { 
                        edges.map((category, key) => (
                          category.node.posts.nodes.length > 0 
                            ? (<Dropdown.Item key={key} href={`/category/${category.node.slug}`} eventKey={category.node.name} >{category.node.name}</Dropdown.Item>)
                            : null
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}