/**
 * Footer component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'

const url = 'https://pressoffice.direct';

const Footer = () => (
    <footer>
        <div className="container-fluid text-left">
            <div className="row footer-container-row align-items-end">
                <div className="col-6 col-md-3 col-lg-2 mb-md-0 mb-3">
                    <h5>Useful links</h5>
                    <ul className="list-unstyled">
                        <li className="footer-link-holder">
                            <a className="footer-link-general" href={`${url}/product`} target="_blank" rel="noopener noreferrer">Product</a>
                        </li>
                        <li className="footer-link-holder">
                            <a className="footer-link-general" href={`${url}/services`} target="_blank" rel="noopener noreferrer">Services</a>
                        </li>
                        <li className="footer-link-holder">
                            <a className="footer-link-general" href={`${url}/journalist-signup`} target="_blank" rel="noopener noreferrer">For Journalists</a>
                        </li>
                    </ul>
                </div>
                <div className="col-6 col-md-3 col-lg-4 mb-md-0 mb-3">
                    <h5>More info</h5>
                    <ul className="list-unstyled">
                        <li className="footer-link-holder">
                            <a className="footer-link-general" href={`${url}/faq`} target="_blank" rel="noopener noreferrer">FAQ</a>
                        </li>
                        <li className="footer-link-holder">
                            <a className="footer-link-general" href={`${url}/careers`} target="_blank" rel="noopener noreferrer">Careers</a>
                        </li>
                        <li className="footer-link-holder">
                            <a className="footer-link-general" href={`${url}/contact-us`} target="_blank" rel="noopener noreferrer">Contact</a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 mt-md-0 mt-3 text-left text-md-right">
                    <div className="row footer-copyright-container-row">
                        <div className="col-12">
                            <p className="footer-copyright-container align-bottom">
                                Made with love in Singapore <br />
                                © PressOffice.direct All rights reserved.<br className="rwd-break" />
                                <a className="footer-link-general ml-0 ml-lg-1" href={`${url}/terms-of-use`} target="_blank" rel="noopener noreferrer">Terms of use</a>
                                <span className="mx-1">|</span>
                                <a className="footer-link-general" href={`${url}/privacy-policy`} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="horizontal-line clearfix w-100 " />
            </div>
        </div>
    </footer>
);

export default Footer