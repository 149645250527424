import React from "react"
import { Link } from "gatsby"
import Footer from "./footer"
import '../css/global.css'
import MainMenu from "./menu"
import { CategoriesList } from "../components/categories"
import { Row, Col } from "react-bootstrap";

const Layout = ({ isHomePage, children }) => {
  // const {
  //   wp: {
  //     generalSettings: { title },
  //   },
  // } = useStaticQuery(graphql`
  //   query LayoutQuery {
  //     wp {
  //       generalSettings {
  //         title
  //         description
  //       }
  //     }
  //   }
  // `)

  return (
    <div data-is-root-path={isHomePage}>
      <header className="global-header">
        <div className="container-fluid bg-color-container">
          <MainMenu />
        </div>
      </header>

      <main className="container" 
        style={{
          minHeight:`90vh`
        }}>
        <Row>
          <Col className="col-lg-8">
            <Row className="mb-4 align-items-center">
              <Col className="col-lg-3 col-md-3 col-4">
                {isHomePage? (
                  <h3 className="top-text mb-0">
                    Latest
                  </h3>
                ) : (
                  <h3 className="top-text mb-0">
                    <Link to="/">Back</Link>
                  </h3>
                )}
               
              </Col>
              <Col className="col-lg-9 col-md-9 col-8">
                <CategoriesList />
              </Col>
              {/* <Col className="col-lg-3 col-md-4 col-2"></Col> */}
            </Row>
          </Col>
        </Row>

        {children}

      </main>

      <Footer/>
      
    </div>
  )
}

export default Layout
