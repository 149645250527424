import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Nav, Navbar } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image"

class ToggleSwitch extends React.Component {
  
  state = {
    active: false,
  }
  render() {
    return (
      <Navbar expand="lg" className="px-0">
      <Navbar.Brand >
      <a href="https://pressoffice.direct">
        <StaticImage
          src="../../content/assets/pressoffice_02.png"
          alt="Pressoffice"
          placeholder="tracedSVG"
          layout="fixed"
          width={200}
          quality="40"
        />
      </a>
    </Navbar.Brand>
        <button onClick={() => this.setState({active: !this.state.active})} className={ this.state.active ? "navbar-toggler open" : "navbar-toggler collapsed" } type="button" id="nav-icon1" data-toggle="collapse" aria-expanded="false" data-target="#basic-navbar-nav" aria-controls="basic-navbar-nav" aria-label="Toggle navigation">
          <span></span>
          <span></span>
          <span></span>
        </button>
      <Navbar.Collapse id="basic-navbar-nav" className={ this.state.active? "justify-content-end show" : "justify-content-end"}>
      <Nav >
          <StaticQuery query={graphql`
    {
      wpMenu(id: {eq: "dGVybToy"}) {
        menuItems {
          nodes {
            id
            label
            url
            order
          }
        }
      }
      }
    `} render={props => (
  
                  props.wpMenu.menuItems.nodes.map((item, index) => (
  
                      <Nav.Item key={index}>
                          <Nav.Link href={item.url}>
                              {item.label}
                          </Nav.Link>
                      </Nav.Item>
                  ))
  
              )} />
      </Nav>
  </Navbar.Collapse>
  </Navbar>
    ); 
  }
}


const MainMenu = () => (
    <ToggleSwitch />
);


export default MainMenu;